<template>
  <div class="bet-suggested" :style="rowOrientation">
    <div class="bet-suggested__team-logo">
      <img v-if="bet.bet_suggested.home_team_logo" :src="getTeamLogo(bet, 'home')" alt="Equipo local" />
    </div>
    <div class="bet-suggested__suggestion">
      {{ bet.bet_suggested.suggestion }}
    </div>
    <div class="bet-suggested__team-logo">
      <img v-if="bet.bet_suggested.away_team_logo" :src="getTeamLogo(bet, 'away')" alt="Equipo visitante" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetSuggested',
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rowOrientation() {
      if (!this.bet.bet_suggested.home_team_logo && this.bet.bet_suggested.away_team_logo) {
        return {
          'flex-direction': 'row-reverse',
        };
      }
      return {
        'flex-direction': 'row',
      };
    },
  },
  methods: {
    getTeamLogo(bet, teamType) {
      const prop = `${teamType}_team_logo`;
      if (bet.provisionalTeamLogo) {
        return `${bet.provisionalTeamLogo}/${bet.bet_suggested[prop]}`;
      } else if (bet.bet_suggested[prop]) {
        return `https://az755631.vo.msecnd.net/teams-80/${bet.bet_suggested[prop]}`;
      }
      return '/assets/icons/icn-foco-rojo.svg';
    },
  },
};
</script>

<style scoped lang="scss">
.bet-suggested {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: solid 2px #f0f0f0;
  padding: 0.5rem 1rem;

  &__team-logo {
    width: 38px;
    height: 38px;
  }

  &__suggestion {
    font-family: Circular-Std-Book, sans-serif;
    font-size: 1rem;
  }

  @media screen and (max-width: 380px) {
    padding: 1rem 0.1rem;
    border-radius: 12px;

    &__suggestion {
      font-size: 0.8rem;
    }
  }
}
</style>
