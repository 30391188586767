<template>
  <div class="game-info">
    <div class="game-info__home-team">
      <div class="logo">
        <img :src="getTeamLogo(bet, 'home')" alt="Equipo local" />
      </div>
      <div class="name">{{ bet.home_team_acronym }}</div>
    </div>
    <div class="game-info__vs">VS</div>
    <div class="game-info__away-team">
      <div class="name">{{ bet.away_team_acronym }}</div>
      <div class="logo">
        <img :src="getTeamLogo(bet, 'away')" alt="Equipo visitante" />
      </div>
    </div>
    <div class="game-info__league">
      <div>{{ bet.tournament_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetGameInfo',
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTeamLogo(bet, teamType) {
      const prop = `${teamType}_team_logo`;
      if (bet.provisionalTeamLogo) {
        return `${bet.provisionalTeamLogo}/${bet[prop]}`;
      } else if (bet[prop]) {
        return `https://az755631.vo.msecnd.net/teams-80/${bet[prop]}`;
      }
      return '/assets/icons/icn-foco-rojo.svg';
    },
  },
};
</script>

<style scoped lang="scss">
@mixin teamLayout {
  width: 100%;
  display: flex;
  align-items: center;
}
.game-info {
  display: grid;
  grid-template-columns: 1fr 3.5rem 1fr;
  position: relative;
  color: #494a4b;
  padding: 1rem;

  & .name {
    font-size: 1.29rem;
    font-family: Circular-Std-Book, sans-serif;
    font-weight: 500;
    width: 3.4rem;
  }

  & .logo {
    height: 38px;
    width: 38px;
    margin: 0 0.2rem;
  }

  &__home-team {
    @include teamLayout;
    justify-content: flex-end;
  }

  &__away-team {
    @include teamLayout;
    justify-content: flex-start;
  }

  &__vs {
    align-self: center;
    font-size: 1rem;
    font-family: Circular-Std-Book, sans-serif;
  }

  &__league {
    position: absolute;
    left: 50%;
    bottom: 10px;

    & > div {
      position: relative;
      left: -50%;
      font-family: Circular-Std-Book, sans-serif;
      font-size: 0.625rem;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 580px) {
  .game-info {
    grid-template-columns: 1fr 1.5rem 1fr;

    & .name {
      font-size: 0.8rem;
    }

    &__home-team {
      flex-direction: column;
    }

    &__away-team {
      flex-direction: column;

      & .logo {
        order: -1;
      }
    }

    &__league {
      bottom: 0;
    }
  }
}
</style>
