<template>
  <div ref="parlay" class="parlay">
    <Transition name="fade">
      <div v-if="isParlayEditVisible" class="parlay__parlay-edit">
        <ParlayEdit
          :total-bets="totalBets"
          :total-bets-by-risk="totalBetsByRisk"
          :parlay-bets="parlayBets"
          :team-id="teamId"
          :is-premium-user="isPremiumUser"
          :parent-height="$refs.parlay.clientHeight"
          @height-change="onHeightChanged"
          @cancel="onCancel"
          @apply-changes="onApplyChanges"
          @premium-disabled-clicked="onClickPremiumDisabled($event)"
        />
      </div>
    </Transition>
    <div class="parlay__edit">
      <template v-if="!isPremiumUser">
        <div ref="parlayEdit" class="parlay__edit__button-hidden">
          <div class="icon">
            <img src="/assets/icons/btn-edit-green.svg" alt="Edita parley" />
          </div>
          <div class="text">Modificar Parlay</div>
        </div>
      </template>
      <template v-else>
        <div ref="parlayEdit" class="parlay__edit__button" @click="onParlayEditClicked">
          <div class="icon">
            <img src="/assets/icons/btn-edit-green.svg" alt="Edita parley" />
          </div>
          <div class="text">Modificar Parlay</div>
        </div>
      </template>
    </div>
    <div class="parlay__details">
      <div class="parlay__details__bets">
        <div class="parlay__details__bets__header">
          <div>Partido</div>
          <div>Apuesta</div>
        </div>

        <div class="table-overlay-container" v-if="!isPremiumUser">
          <div class="table-overlay--desktop">
            <div><b>Solo para Usuarios Premium</b> <br />($99 pesos al mes)</div>
            <div class="button button-video is-rounded" @click="showVideo">
              <span class="icon">
                <i class="mdi mdi-play-circle-outline"></i>
              </span>
              <span>Personaliza tu parlay</span>
            </div>
          </div>
        </div>
        <div
          v-for="bet in parlayBets"
          :key="bet.bet_id"
          class="parlay__details__bets__bet"
          :class="{ blurred: !isPremiumUser }"
        >
          <BetGameInfo :bet="bet" />
          <div>
            <div>
              <BetSuggested :bet="bet" />
            </div>
          </div>
        </div>
      </div>
      <div class="parlay__details__parlay-info">
        <div class="parlay__details__parlay-info__amount">
          <div>Parlay apostando:</div>
          <div class="amount-wrapper">
            <InputInteger v-model="amount" ref="parlayAmount" :input-classes="['with-currency']" maxlength="6" />
            <span class="amount-wrapper__symbol" @click="onAmountClicked">$</span>
            <span class="amount-wrapper__currency" @click="onAmountClicked">mxn</span>
          </div>
        </div>
        <div class="topic probability">
          <div><span>Probabilidad </span></div>
          <div>
            <span>Total:</span><span class="value">{{ (totalProbability * 100).toFixed(0) }}%</span>
          </div>
        </div>
        <div class="separator" />
        <div class="topic win">
          <div><span>Para Ganar:</span></div>
          <div>
            <span class="value">${{ payout.toFixed(2) }}</span>
            <span class="currency"> MXN</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BetGameInfo from '@/components/Elements/BetGameInfo';
import BetSuggested from '@/components/Elements/BetSuggested';
import InputInteger from '@/components/Form/InputInteger';
import ParlayEdit from '@/components/Sections/BetTool/ParlayEdit';
import { widgetApostadoresParlayModificar } from '@/utils/analytics';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Parlay',
  components: {
    BetGameInfo,
    BetSuggested,
    InputInteger,
    ParlayEdit,
  },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
      default: 0,
    },
    /**
     * All bets from all tournaments
     * {Array<{
     *   bet_id: number,
     *   game_id: number,
     *   date: string,
     *   season_id: number,
     *   tournament_id: number,
     *   home_team_id: number,
     *   home_team_name: string,
     *   home_team_logo: string,
     *   home_team_acronym: string,
     *   away_team_id: number,
     *   away_team_name: string,
     *   away_team_logo: string,
     *   away_team_acronym: string,
     *   bet_type_id: 1,
     *   bet_suggested: {
     *     home_team_logo: string,
     *     away_team_logo: string,
     *     additional_icon: string,
     *     suggestion: string,
     *   },
     *   prob_initial: number,
     *   odd: number,
     *   prob_actual: number,
     *   difference_probability: string,
     *   bet_risk: number,
     * }>}
     */
    totalBets: {
      type: Array,
      required: true,
    },
    /**
     * Each risk type array contains bets equal to the bets described in the prop totalBets
     * {Object} totalBetsByRisk
     * {Array<Object>} totalBetsByRisk.1 - All low risk bets
     * {Array<Object>} totalBetsByRisk.2 - All medium risk bets
     * {Array<Object>} totalBetsByRisk.3 - All high risk bets
     */
    totalBetsByRisk: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amount: 100,
      isParlayEditVisible: false,
      initialHeight: 0,
      parlayBets: [],
      parlayBetsLimit: 2,
      riskLimit: 4,
      lastHeightChanged: 0,
      betsTableOverlayStyle: {
        width: '0px',
        'margin-left': '0px',
        transform: 'none',
      },
    };
  },
  watch: {
    totalBetsByRisk(newValue) {
      if (newValue) {
        this.fillParlayBets();
      }
    },
    isPremiumUser(newValue) {
      this.isParlayEditVisible = false;
      if (!newValue) {
        sessionStorage.removeItem('parlaySelectedTeams');
        this.fillParlayBets();
      }
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    totalProbability() {
      return this.parlayBets.reduce((accumulator, bet) => {
        let currentProbability = accumulator;
        const probActual = bet.prob_actual / 100;
        currentProbability = currentProbability ? currentProbability * probActual : currentProbability + probActual;
        return currentProbability;
      }, 0);
    },
    payout() {
      const odd =
        this.totalProbability <= 0.5
          ? 100 / this.totalProbability - 100
          : (100 * this.totalProbability) / (this.totalProbability - 1);
      return odd > 0 ? (this.amount * odd) / 100 + this.amount : (this.amount * 100) / Math.abs(odd) + this.amount;
    },
  },
  methods: {
    ...mapMutations('general', ['setPreviewSectionAnalytics', 'setVideoModalVisibility', 'setVideoSrc']),
    showVideo() {
      this.setVideoSrc('https://az755631.vo.msecnd.net/golstats-bets/VideoParlay.mp4');
      this.setVideoModalVisibility(true);
    },
    onClickPremiumDisabled(event) {
      this.setPreviewSectionAnalytics('');
      this.$emit('premium-disabled-clicked', event);
    },
    onApplyChanges(parlayProposal) {
      var el = this.$refs['parlay'];

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
      this.parlayBets = [...parlayProposal];
    },
    onCancel() {
      var el = this.$refs['parlay'];

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
      this.isParlayEditVisible = false;
      this.$refs.parlay.style.height = 'fit-content';
    },
    onHeightChanged(height) {
      if (this.lastHeightChanged !== height) {
        this.$refs.parlay.style.height = `${height}px`;
      }
    },
    onParlayEditClicked() {
      widgetApostadoresParlayModificar({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      this.isParlayEditVisible = true;
    },
    onAmountClicked() {
      this.$refs.parlayAmount.$el.select();
    },
    fillParlayBets() {
      this.parlayBets = [];
      if (this.teamId) {
        this.fillTeamFavorableBet();
      }
      for (let risk = 1; risk < this.riskLimit; risk++) {
        const betsByCurrentRisk = this.totalBetsByRisk[risk];
        for (const bet of betsByCurrentRisk) {
          const addedGame = this.parlayBets.find(addedBet => addedBet.game_id === bet.game_id);
          if (this.teamId) {
            if (bet.away_team_id !== this.teamId && bet.home_team_id !== this.teamId && !addedGame) {
              this.parlayBets.push(bet);
            }
          } else if (!addedGame) {
            this.parlayBets.push(bet);
          }
          if (this.parlayBets.length === this.parlayBetsLimit) {
            return;
          }
        }
      }
    },
    fillTeamFavorableBet() {
      let teamBets = this.totalBets.filter(bet => bet.bet_suggested.teams.includes(this.teamId));
      if (teamBets.length) {
        teamBets.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        const teamFavorableBets = { win: null, winTie: null };
        for (const teamBet of teamBets) {
          if (teamBet.bet_suggested.suggestion === 'Gana' && teamBet.bet_risk === 1) {
            teamFavorableBets.win = teamBet;
            break;
          } else if (teamBet.bet_suggested.suggestion === 'G/E') {
            teamFavorableBets.winTie = teamBet;
            break;
          }
        }
        if (teamFavorableBets.win || teamFavorableBets.winTie) {
          this.parlayBets.push(teamFavorableBets.win || teamFavorableBets.winTie);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@mixin betsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  & > div {
    width: 70%;

    &:first-child {
      justify-self: flex-end;
    }

    @media screen and (max-width: 862px) {
      width: 100%;
    }
  }
}

.button-video {
  margin: 0.5rem;
  color: white;
  background-color: #428ee6;
  font-size: 1rem;
  @media screen and (max-width: 400px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 350px) {
    font-size: 0.64rem;
  }
  padding: 20px 30px;
  font-family: Circular-Std-Bold, sans-serif;
  .icon {
    margin-left: 10px !important;
    @media screen and (max-width: 400px) {
      margin-left: 5px !important;
    }
    i {
      font-size: 1.5em;
      @media screen and (max-width: 400px) {
        font-size: 1.9em;
        margin-right: 8px;
      }
    }
  }
}

$blur: 10px;

.blurred {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;
}
.table-overlay-container {
  overflow: visible;
  width: 100%;
  height: 90%;
  z-index: 9;
  position: absolute;
}

@mixin table-overlay {
  padding: 0.7em;
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  font-family: 'Circular-Std-Medium';
  border: dotted 2px #5c5c5c;
  color: #3e3e3e;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

@mixin font-circular-bold {
  font-family: 'Circular-Std-Black';
  color: #272727f5;
}

.table-overlay {
  &--mobile {
    @include table-overlay;
    font-size: clamp(0.5rem, 1.5rem, 3vw);
    & > b {
      @include font-circular-bold;
    }
  }
  &--desktop {
    @include table-overlay;
    @media screen and (min-width: 1280px) {
      font-size: clamp(0.5rem, 1.1rem, 1vw);
      & > b {
        @include font-circular-bold;
      }
    }
  }

  &--mobile,
  &--desktop {
    transition: width 50ms;
    transition-timing-function: linear;
  }
}

.parlay {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__parlay-edit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    z-index: 1;
  }

  &__edit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.4rem 1rem;

    &__button {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 8px;
      border: solid 3px #dedbdb;
      transition: box-shadow 0.2s ease-in-out, background 400ms;

      & > .icon {
        height: 2rem;
        width: 2rem;
      }

      & > .text {
        padding-top: 0.2rem;
        margin-left: 0.75rem;
        font-family: Avenir-Demi, sans-serif;
        font-size: 1.25rem;
        color: #272727;
      }
    }
    &__button-hidden {
      position: relative;
      overflow: hidden;
      display: flex;
      opacity: 0.4;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.7rem;
      border-radius: 8px;
      border: solid 3px #dedbdb;

      & > .icon {
        height: 2rem;
        width: 2rem;
      }

      & > .text {
        padding-top: 0.2rem;
        margin-left: 0.75rem;
        font-family: Avenir-Demi, sans-serif;
        font-size: 1.25rem;
        color: #272727;
      }
    }
  }

  &__details {
    width: 94%;

    &__bets {
      width: 100%;
      position: relative;

      &__header {
        @include betsGrid;
        background-color: #242424;
        color: white;
        padding: 0.4rem;
        text-transform: uppercase;
      }

      &__bet {
        @include betsGrid;
        border-bottom: solid 1px #e3e3e3;

        & > div:last-child {
          width: 70%;
          padding: 0.43rem 0;
          display: flex;
          justify-content: center;

          & > div {
            width: 60%;
          }

          @media screen and (max-width: 862px) {
            width: 100%;

            & > div {
              width: 90%;
            }
          }
        }
      }
    }

    &__parlay-info {
      position: relative;
      width: 100%;
      border-radius: 12px;
      background-color: rgba(216, 216, 216, 0.1);
      display: flex;
      margin-top: 1rem;
      align-items: center;
      padding: 0.4rem 0 0.3rem;
      font-family: Circular-Std-Book, sans-serif;
      color: #272727;
      font-size: 1.375rem;

      & .topic {
        font-size: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &.probability {
          & > div:first-child {
            margin-right: 0.4rem;
          }
        }

        & .value {
          margin-left: 0.5rem;
          font-family: Circular-Std, sans-serif;
        }

        & .currency {
          font-family: Circular-Std, sans-serif;
          font-size: 1rem;
        }
      }

      &__amount {
        display: flex;
        justify-content: center;
        align-items: center;

        & > div:first-child {
          margin-right: 0.5rem;

          @media screen and(max-width: 396px) {
            font-size: 1rem;
          }
        }

        & .amount-wrapper {
          position: relative;

          & > span {
            position: absolute;
            font-family: Circular-Std-Book, sans-serif;
            color: #272727;
            font-size: 1.3rem;
            font-weight: bold;

            &.amount-wrapper__symbol {
              left: 0.4rem;
              bottom: 0.08rem;
            }

            &.amount-wrapper__currency {
              right: 0.4rem;
              bottom: 0.19rem;
              font-size: 0.875rem;
              text-transform: uppercase;
            }
          }
        }

        &::v-deep .with-currency {
          width: 9rem;
          height: 2rem;
          padding: 0 2.6rem 0 1.2rem;
          font-size: 1.3rem;
          font-family: Circular-Std-Book, sans-serif;
          font-weight: bold;
          color: #272727;
        }
      }

      & > div {
        flex-grow: 1;
        padding: 0 0.5rem;

        &.separator {
          flex-grow: 0;
          height: 1rem;
          border-left: solid 1px #272727;
          padding: 0;
          margin: 0 0.5rem;
        }
      }

      @media screen and(max-width: 886px) {
        margin-top: 5rem;
        &__amount {
          width: 100%;
          position: absolute;
          top: -50px;
        }
      }

      @media screen and(max-width: 502px) {
        & .topic {
          font-size: 1rem;
          &.probability {
            justify-content: flex-end;
          }

          &.win {
            justify-content: flex-start;
          }

          & .value {
            margin-left: 0.5rem;
            font-family: Circular-Std, sans-serif;
            margin: 0;
          }

          & .currency {
            font-family: Circular-Std, sans-serif;
            font-size: 0.8rem;
          }
        }

        & > div.separator {
          height: 3rem;
          margin: 0 1rem;
        }
      }
    }
  }
}
@media (hover: hover) and (pointer: fine) {
  .parlay__edit__button:hover {
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 0px 9px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 13px -7px #000000, 0px 0px 9px 1px rgba(0, 0, 0, 0.1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
