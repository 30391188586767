<template>
  <input
    :value="value"
    ref="inputInteger"
    :readonly="readonly"
    :disabled="disabled"
    class="input"
    :class="inputClasses"
    :maxlength="maxlength"
    :style="{ 'font-family': fontFamily }"
    type="text"
    @input="onInput"
    @keypress="onKeyPress"
    @blur="onBlur"
    onClick="this.select();"
  />
</template>

<script>
import { parlayBetAmount, tipsBetAmount } from '@/utils/analytics';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    sectionName: {
      type: String,
      default: '',
    },

    inputClasses: {
      type: Array,
      default: () => [],
    },

    maxlength: {
      type: [Number, String],
      default: '20',
    },

    fontFamily: {
      type: String,
      default: 'inherit',
    },
  },

  data() {
    return {
      integerRegEx: new RegExp(`^[0-9]*$`),
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    onInput($event) {
      if (!$event.target.value.length) {
        $event.target.value = 0;
        $event.target.select();
        return;
      }
      let integer = '';
      if (!Number.isNaN(Number($event.target.value))) {
        integer = $event.target.value.replace(/\.[0-9]*/g, '');
      }
      if (integer) {
        $event.target.value = integer;
        this.$emit('input', Number(integer));
      } else {
        this.emitDefault();
      }
    },
    onKeyPress($event) {
      if (!this.integerRegEx.test(`${$event.target.value}${$event.key}`)) {
        $event.preventDefault();
      }
    },
    onBlur() {
      if (!this.$refs.inputInteger.value.length || Number(this.$refs.inputInteger.value) === 0) {
        this.emitDefault();
      }
      if (this.sectionName === 'parlay') {
        parlayBetAmount({ is_premium: this.getPremiumAccount, value: this.value, golstats: this.getIsGolstats });
      } else if (this.sectionName === 'tips') {
        tipsBetAmount({ is_premium: this.getPremiumAccount, value: this.value, golstats: this.getIsGolstats });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/*input {
  width: 5rem;
  padding-left: 1rem;
  padding-right: 2rem;
}*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
