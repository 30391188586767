<template>
  <div ref="parlayEdit" class="parlay-edit">
    <div class="parlay-edit__close" @click="onClose">
      <img src="/icons/icn-close-dark.svg" alt="Cerrar" />
    </div>
    <div class="parlay-edit__risk-teams">
      <div class="parlay-edit__risk-teams__risk">
        <div class="title">Número de partidos y riesgo:</div>
        <div class="body">
          <div class="risk-list">
            <TransitionGroup name="list">
              <div v-for="(risk, index) in risks" :key="`${index}i`" class="risk-list__item">
                <div class="index">
                  <div>{{ index + 1 }}.</div>
                </div>
                <div class="options">
                  <BRadio
                    v-model="risk.vModel"
                    :name="`risk${index}`"
                    :native-value="1"
                    @change.native="onClickSelectRisk('Bajo riesgo')"
                  >
                    Bajo riesgo
                  </BRadio>
                  <BRadio
                    v-model="risk.vModel"
                    :name="`risk${index}`"
                    :native-value="2"
                    @change.native="onClickSelectRisk('Mediano riesgo')"
                  >
                    Mediano riesgo
                  </BRadio>
                  <BRadio
                    v-model="risk.vModel"
                    :name="`risk${index}`"
                    :native-value="3"
                    @change.native="onClickSelectRisk('Alto riesgo')"
                  >
                    Alto riesgo
                  </BRadio>
                  <div v-show="index > 1" class="remove" @click="onRemoveRiskClicked(index)">
                    <img src="/icons/icn-close-dark.svg" alt="Quitar apuesta" />
                  </div>
                </div>
              </div>
            </TransitionGroup>
            <div class="add-risk">
              <button @click="onAddRiskClicked"><span class="plus">+</span>Agregar apuesta</button>
            </div>
          </div>
        </div>
      </div>
      <div class="parlay-edit__risk-teams__teams">
        <div class="title">Apostar a favor de los siguientes equipos:</div>
        <div class="body">
          <div class="teams" :key="teamsKey">
            <div v-for="(team, index) in availableTeams" :key="`${index}t`">
              <div
                v-if="!isPremiumUser"
                class="team-blocker"
                @click="$emit('premium-disabled-clicked', 'Popup_CheckTeams')"
              />
              <BCheckbox
                v-model="selectedTeams"
                :native-value="team.id"
                @change.native="onClickSelectTeam($event)"
                class="team-checkbox"
              >
                <div class="container">
                  <div class="team-logo">
                    <img :src="getTeamLogo(team)" alt="Equipo" />
                  </div>
                  <div class="team-name">{{ team.name }}</div>
                </div>
              </BCheckbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="finalErrorMsg" ref="errorMsg" class="parlay-edit__error-msg">
      <div class="error-msg animate__animated animate__shakeX">{{ finalErrorMsg }}</div>
    </div>
    <div class="parlay-edit__actions">
      <div>
        <button class="cancel" @click="onClose(true)">Cancelar</button>
      </div>
      <div>
        <button class="apply" @click="onApplyClicked">Aplicar cambios</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  widgetApostadoresParlayModificarRiesgo,
  widgetApostadoresParlayModificarAgregar,
  widgetApostadoresParlayModificarRiesgoCerrar,
  widgetApostadoresModificarParlayDeselecEquipo,
  widgetApostadoresModificarParlaySelecEquipo,
  widgetApostadoresModificarParlayAplicar,
  widgetApostadoresModificarParlayCancelar,
} from '@/utils/analytics';
import { mapGetters } from 'vuex';
import { BRadio } from 'buefy/dist/esm/radio';
import { BCheckbox } from 'buefy/dist/esm/checkbox';

const risksNames = {
  1: 'Bajo riesgo',
  2: 'Mediano riesgo',
  3: 'Alto riesgo',
};
let parlayEditResizeObserver = null;

export default {
  name: 'ParlayEdit',
  components: {
    BCheckbox,
    BRadio,
  },
  props: {
    parentHeight: {
      type: Number,
      default: 0,
    },
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
      default: 0,
    },
    /**
     * All bets from all tournaments
     * {Array<{
     *   bet_id: number,
     *   game_id: number,
     *   date: string,
     *   season_id: number,
     *   tournament_id: number,
     *   home_team_id: number,
     *   home_team_name: string,
     *   home_team_logo: string,
     *   home_team_acronym: string,
     *   away_team_id: number,
     *   away_team_name: string,
     *   away_team_logo: string,
     *   away_team_acronym: string,
     *   bet_type_id: 1,
     *   bet_suggested: {
     *     home_team_logo: string,
     *     away_team_logo: string,
     *     additional_icon: string,
     *     suggestion: string,
     *   },
     *   prob_initial: number,
     *   odd: number,
     *   prob_actual: number,
     *   difference_probability: string,
     *   bet_risk: number,
     * }>}
     */
    totalBets: {
      type: Array,
      required: true,
    },
    /**
     * Each risk type array contains bets equal to the bets described in the prop totalBets
     * {Object} totalBetsByRisk
     * {Array<Object>} totalBetsByRisk.1 - All low risk bets
     * {Array<Object>} totalBetsByRisk.2 - All medium risk bets
     * {Array<Object>} totalBetsByRisk.3 - All high risk bets
     */
    totalBetsByRisk: {
      type: Object,
      required: true,
    },
    /**
     * Bets already selected from parlay component, each bet is equal to the bets described
     * in the prop totalBets
     */
    parlayBets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      risks: [],
      availableTeams: [],
      selectedTeams: [],
      teamsKey: 0,
      localTotalBetsByRisk: [],
      localParlayBets: [],
      availableBets: {
        1: [],
        2: [],
        3: [],
      },
      parlayProposal: [],
      errorMsg: '',
      isModalActive: true,
      missingRisks: [],
    };
  },
  watch: {
    totalBetsByRisk: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.localTotalBetsByRisk = Object.entries(newValue).reduce((accumulator, [risk, bets]) => {
            accumulator[risk] = [...bets];
            return accumulator;
          }, {});
          this.setAvailableTeams();
        }
      },
    },
    parlayBets: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.length) {
          this.localParlayBets = [...newValue];
          this.fillRisks();
        }
      },
    },
    selectedTeams(newValue, oldValue) {
      if (newValue.length < 2) {
        this.selectedTeams = oldValue;
        this.teamsKey++;
      } else {
        this.fillParlayProposal();
      }
    },
    risks: {
      deep: true,
      handler() {
        this.fillParlayProposal();
      },
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    finalErrorMsg() {
      return this.missingRisks.length
        ? `No hay apuestas suficientes de tipo ${this.missingRisks.reduce((accumulator, missingRisk, index, array) => {
            const separator = array.length > 1 && index === array.length - 1 ? ' y ' : index > 0 ? ', ' : '';
            // eslint-disable-next-line no-param-reassign
            accumulator += `${separator}"${missingRisk}"`;
            return accumulator;
          }, '')}, intenta cambiando tu selección de equipos, o cambiando los riesgos de tus apuestas.`
        : '';
    },
  },
  methods: {
    getTeamLogo(team) {
      return team.provisionalLogo || `https://az755631.vo.msecnd.net/teams-80/${team.logo}`;
    },
    onClickSelectTeam(event) {
      event.target.checked
        ? widgetApostadoresModificarParlaySelecEquipo({
            is_premium: this.getPremiumAccount,
            team_id: event.target.value,
            golstats: this.getIsGolstats,
          })
        : widgetApostadoresModificarParlayDeselecEquipo({
            is_premium: this.getPremiumAccount,
            team_id: event.target.value,
            golstats: this.getIsGolstats,
          });
    },
    onClickSelectRisk(value) {
      widgetApostadoresParlayModificarRiesgo({
        is_premium: this.getPremiumAccount,
        value,
        golstats: this.getIsGolstats,
      });
    },
    setAvailableTeams() {
      const availableTeams = Object.values(this.localTotalBetsByRisk).reduce((accumulator, bets) => {
        bets.forEach(bet => {
          if (bet.bet_suggested.home_team_id && !accumulator.has(bet.bet_suggested.home_team_id)) {
            accumulator.set(bet.bet_suggested.home_team_id, {
              id: bet.home_team_id,
              name: bet.home_team_name,
              logo: bet.home_team_logo,
              provisionalLogo: bet.provisionalTeamLogo ? `${bet.provisionalTeamLogo}/${bet.home_team_logo}` : '',
            });
          }
          if (bet.bet_suggested.away_team_id && !accumulator.has(bet.bet_suggested.away_team_id)) {
            accumulator.set(bet.bet_suggested.away_team_id, {
              id: bet.away_team_id,
              name: bet.away_team_name,
              logo: bet.away_team_logo,
              provisionalLogo: bet.provisionalTeamLogo ? `${bet.provisionalTeamLogo}/${bet.away_team_logo}` : '',
            });
          }
        });
        return accumulator;
      }, new Map());
      this.availableTeams = Array.from(availableTeams.values());
      const availableTeamsIds = this.availableTeams.map(team => team.id);
      let storedSelectedTeams = sessionStorage.getItem('parlaySelectedTeams');
      let selectedTeams = availableTeamsIds;
      if (storedSelectedTeams) {
        storedSelectedTeams = JSON.parse(storedSelectedTeams);
        storedSelectedTeams = availableTeamsIds.filter(availableTeamId => {
          return storedSelectedTeams.includes(availableTeamId);
        });
        if (storedSelectedTeams.length > 1) {
          selectedTeams = [...storedSelectedTeams];
        }
      }
      this.selectedTeams = selectedTeams;
    },
    setUpResizeObserver() {
      if (window.ResizeObserver) {
        parlayEditResizeObserver = new ResizeObserver(() => {
          this.$emit('height-change', this.$refs.parlayEdit.clientHeight);
        });
        parlayEditResizeObserver.observe(this.$refs.parlayEdit);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    fillRisks() {
      this.risks = this.parlayBets.map(bet => {
        return {
          vModel: bet.bet_risk,
        };
      });
    },
    fillTeamFirstBet(availableBets, auxRisks) {
      let betRisk = 1;
      if (this.risks[0].vModel === this.parlayBets[0].bet_risk) {
        this.parlayProposal.push(this.parlayBets[0]);
        auxRisks.splice(0, 1);
      } else {
        betRisk = this.risks[0].vModel;
        const teamBet = availableBets[betRisk].find(bet => bet.bet_suggested.teams.includes(this.teamId));
        if (teamBet) {
          this.parlayProposal.push(teamBet);
          auxRisks.splice(0, 1);
          // eslint-disable-next-line no-param-reassign
          availableBets = this.getFilteredBetsByTeam(availableBets);
        }
      }
    },
    fillParlayProposal() {
      this.parlayProposal = [];
      this.missingRisks = [];
      const auxRisks = [...this.risks];
      for (let i = 0; i < this.risks.length; i++) {
        let availableBets = this.getAvailableBets();
        // todo: check if this validation applies
        // if (this.teamId && 1 === 0) {
        //   this.fillTeamFirstBet(availableBets, auxRisks);
        // }
        const availableBetsByLength = Object.entries(availableBets);
        availableBetsByLength.sort((a, b) => {
          return a[1].length - b[1].length;
        });
        for (const [riskString] of availableBetsByLength) {
          const lowestRisk = Number(riskString);
          const selectedRiskIndex = auxRisks.findIndex(risk => risk.vModel === lowestRisk);
          if (selectedRiskIndex > -1) {
            const selectedRisk = auxRisks[selectedRiskIndex];
            let bestDPBet = null;
            for (const selectedRiskBet of availableBets[selectedRisk.vModel]) {
              if (this.teamId && selectedRiskBet.losing_team === this.teamId) {
                continue;
              }
              bestDPBet = selectedRiskBet;
            }
            if (bestDPBet) {
              this.parlayProposal.push(availableBets[selectedRisk.vModel][0]);
              auxRisks.splice(selectedRiskIndex, 1);
              break;
            }
            if (!this.missingRisks.includes(risksNames[selectedRisk.vModel])) {
              this.missingRisks.push(risksNames[selectedRisk.vModel]);
            }
            auxRisks.splice(selectedRiskIndex, 1);
            break;
          }
        }
      }
    },
    getAvailableBets() {
      const availableBets = {
        1: [],
        2: [],
        3: [],
      };
      for (const [risk, bets] of Object.entries(this.localTotalBetsByRisk)) {
        availableBets[risk] = bets.filter(bet => {
          return (
            bet &&
            !this.parlayProposal.find(betProposal => betProposal.game_id === bet.game_id) &&
            this.selectedTeams.includes(bet.bet_suggested.home_team_id || bet.bet_suggested.away_team_id)
          );
        });
      }
      this.availableBets = availableBets;
      return {
        ...availableBets,
      };
    },
    /**
     *
     * @param {{}} availableBets
     */
    getFilteredBetsByTeam(availableBets) {
      return Object.entries(availableBets).reduce(
        (newAvailableBets, [risk, bets]) => {
          newAvailableBets[risk] = bets.filter(
            bet => bet.away_team_id !== this.teamId && bet.home_team_id !== this.teamId,
          );
          return newAvailableBets;
        },
        {
          1: [],
          2: [],
          3: [],
        },
      );
    },
    storeSelectedInfo() {
      const selectedTeamsStr = JSON.stringify(this.selectedTeams);
      sessionStorage.setItem('parlaySelectedTeams', selectedTeamsStr);
    },
    onClose(isClicked) {
      if (isClicked) {
        widgetApostadoresModificarParlayCancelar({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      }
      this.storeSelectedInfo();
      this.$emit('cancel');
    },
    onApplyClicked() {
      widgetApostadoresModificarParlayAplicar({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      if (this.parlayProposal.length > 1) {
        this.$emit('apply-changes', this.parlayProposal);
        this.onClose();
      }
    },
    onAddRiskClicked() {
      widgetApostadoresParlayModificarAgregar({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
      if (this.isPremiumUser) {
        if (this.finalErrorMsg) {
          // todo
        } else if (this.risks.length < 7) {
          this.risks.push({ vModel: 1 });
        }
      } else {
        this.$emit('premium-disabled-clicked', 'Popup_AddBet');
      }
    },
    onRemoveRiskClicked(index) {
      this.risks.splice(index, 1);
      widgetApostadoresParlayModificarRiesgoCerrar({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.parlayEdit.clientHeight < this.parentHeight) {
        this.$refs.parlayEdit.style.height = `${this.parentHeight}px`;
      } else {
        this.$emit('height-change', this.$refs.parlayEdit.clientHeight);
      }
      this.setUpResizeObserver();
      this.fillParlayProposal();
    });
  },
  beforeDestroy() {
    if (parlayEditResizeObserver) {
      parlayEditResizeObserver.unobserve(this.$refs.parlayEdit);
    }
  },
};
</script>

<style scoped lang="scss">
.parlay-edit {
  display: flex;
  min-height: 100%;
  width: 100%;
  background-color: white;
  padding-top: 2rem;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    margin: 0.5rem;
    color: black;
    cursor: pointer;

    & > img {
      width: 1rem;
      height: 1rem;
    }
  }

  &__risk-teams {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & .title {
        font-size: 1.25rem;
        font-family: Circular-Std-Book, sans-serif;
        color: #272727;
        font-weight: normal;
      }

      & > .body {
        width: 100%;

        & > .risk-list {
          width: 100%;
          display: flex;
          flex-direction: column;

          & div.risk-list__item {
            width: 100%;
            display: flex;
            margin: 0.4rem 0 0.4rem;
          }

          & .index {
            font-family: Avenir-Pro-Bold, sans-serif;
            font-size: 1.25rem;
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 0.8rem;
          }

          & .options {
            width: 27.9rem;
            border-radius: 6px;
            border: solid 1px #d1d1d1;
            display: flex;
            align-items: center;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
            font-size: 1rem;
            font-family: Avenir-medium, sans-serif;
            color: #545454;
            position: relative;

            & .remove {
              width: 0.9rem;
              height: 0.9rem;
              margin: 0 0.5rem;
              cursor: pointer;
            }
          }

          & > .add-risk {
            z-index: 1;
            display: flex;

            & > button {
              margin-left: 15%;
              width: 27.9rem;
              border-radius: 6px;
              box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
              border: solid 1px #d1d1d1;
              background-color: #f6f6f6;
              font-family: Avenir-Demi, sans-serif;
              font-size: 1rem;
              padding: 0.6rem 0.8rem 0.5rem;
              color: #545454;
              cursor: pointer;

              & > .plus {
                font-size: 1.2rem;
                margin-right: 0.6rem;
              }
            }
          }
        }

        & > .teams {
          display: flex;
          flex-wrap: wrap;

          & > div {
            position: relative;
          }

          & div.team-blocker {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }

  & div.error-msg {
    margin-top: 2.5rem;
    background-color: #fdecf0;
    padding: 1rem 1rem;
    border-radius: 4px;
    color: #cc1035;
    font-size: 0.9rem;
    max-width: 80%;
  }

  &__error-msg {
    display: flex;
    justify-content: center;
  }

  &__actions {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;

    & button {
      font-family: Circular-Std-Book, sans-serif;
      font-size: 1.25rem;
      cursor: pointer;

      &.cancel {
        width: 11.125rem;
        background-color: white;
        border-radius: 7px;
        border: solid 2px #525252;
        padding: 0.75rem 0;
      }

      &.apply {
        border: none;
        width: 11.875rem;
        border-radius: 7px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
        background-color: #6eb90f;
        color: white;
        padding: 0.85rem 0;

        &:disabled {
          background-color: rgba(110, 185, 15, 0.3);
        }
      }
    }

    & > div:first-child {
      justify-self: flex-end;
    }
  }

  @media screen and (max-width: 1096px) {
    &__risk-teams {
      grid-template-columns: 1fr;
      row-gap: 3.5rem;

      & > div {
        & .body {
          max-width: 34rem;
        }
      }
    }
  }

  @media screen and (max-width: 546px) {
    &__risk-teams {
      & > div {
        & .title {
          padding: 0 2.5rem;
          text-align: left;
        }
        & .body {
          & > .risk-list {
            .index {
              width: 30%;
            }

            & .options {
              flex-direction: column;
              align-items: flex-start;
              padding: 1rem;
              width: 16rem;

              & > label.b-radio {
                margin: 0.4rem;
              }

              & > .remove {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0.8rem;
              }
            }

            & > .add-risk > button {
              width: 16rem;
              margin-left: 30%;
            }
          }

          & > .teams {
            padding: 0 1rem 0 1.5rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 438px) {
    padding-top: 3rem;
    &__risk-teams {
      & > div {
        & .title {
          padding: 0 2.5rem;
          text-align: left;
        }
        & .body {
          & > .risk-list {
            .index {
              width: 22%;
            }

            & > .add-risk > button {
              margin-left: 22%;
            }
          }
        }
      }
    }

    & div.error-msg {
      background-color: #fdecf0;
      padding: 1rem 1rem;
      border-radius: 4px;
      color: #cc1035;
      font-size: 0.9rem;
      max-width: 100%;
      margin: 2.5rem 0.5rem 0;
    }

    &__actions {
      width: 100%;
      grid-template-columns: 1fr;
      row-gap: 1rem;
      padding: 0.5rem;

      & > div {
        width: 100%;
        & > button {
          &.cancel {
            width: 100%;
          }

          &.apply {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 327px) {
    &__risk-teams {
      & > div {
        & .body {
          & > .risk-list {
            .index {
              width: 20%;
            }

            & .options {
              flex-direction: column;
              align-items: flex-start;
              padding: 1rem;
              margin-right: 0.5rem;
              flex-grow: 1;

              & > label.b-radio {
                margin: 0.4rem;
              }

              & > .remove {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0.8rem;
              }
            }

            & > .add-risk {
              padding: 0.5rem 0;
              justify-content: center;

              & > button {
                width: 96%;
                margin: 0;
              }
            }
          }

          & > .teams {
            padding: 0 1rem 0 2.5rem;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
$primary: #7dd421;

label.b-checkbox.checkbox.team-checkbox {
  position: relative;
  margin: 0.8rem 0.5rem 0;
  padding: 0;

  & div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48px;

    & > div.team-logo {
      width: 43px;
      height: 43px;
    }

    & > div.team-name {
      margin-top: 0.3rem;
      font-size: 0.625rem;
      text-transform: uppercase;
      text-align: center;
    }
  }

  & > span.check {
    position: absolute;
    top: -0.4rem;
    left: 0;
    width: 1rem;
    height: 1rem;
  }
}

.available-bets {
  width: 100%;
  display: flex;
  justify-content: center;
}

.parlay-edit {
  // checkboxes
  .b-checkbox.checkbox input[type='checkbox']:checked + .check {
    background: $primary
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:white' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center center;
    border-color: $primary;
  }

  .b-checkbox.checkbox:hover input[type='checkbox']:not(:disabled) + .check {
    border-color: $primary;
  }

  .b-checkbox.checkbox input[type='checkbox']:focus:checked + .check {
    box-shadow: 0 0 0.5em rgba(125, 212, 33, 0.8);
  }

  // radios
  .b-radio.radio input[type='radio']:checked + .check {
    border-color: $primary;
  }

  .b-radio.radio input[type='radio']:checked + .check + span.control-label {
    color: #545454;
  }

  .b-radio.radio input[type='radio']:not(:checked) + .check + span.control-label {
    color: #787878;
  }

  .b-radio.radio input[type='radio'] + .check:before {
    background-color: $primary;
  }

  .b-radio.radio:hover input[type='radio']:not(:disabled) + .check {
    border-color: $primary;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
